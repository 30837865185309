import { Loading as Spinner } from '@risk-first/ui-loading';
import React from 'react';
import { Container } from './Loading.style';

type LoadingProps = {
  className?: string;
};

export const Loading: React.FC<LoadingProps> = ({ className }) => {
  return (
    <Container className={className}>
      <Spinner />
    </Container>
  );
};
