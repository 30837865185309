import { PayloadAction } from '@reduxjs/toolkit';
import { CurvesResponse } from '../types/CurvesResponse';
import { Dividend } from '../types/Dividend';
import { MarketPricesResponse } from '../types/MarketPricesResponse';
import { PricingMeasure } from '../types/PricingMeasure';
import { SearchResultsResponse } from '../types/SearchResultsResponse';
import { SecuritiesResponse } from '../types/SecuritiesResponse';
import { SecurityHistoryItem } from '../types/SecurityHistoryItem';
import { SecurityInfoResponse } from '../types/SecurityInfoResponse';
import { SecurityReturn } from '../types/SecurityReturn';
import { SearchResultsState } from './SearchResultsState';

const instanceOfResponseType = <R>(response: any, member: string): response is R => response.hasOwnProperty(member);

const onSearchResultsAbortedOrFailureAction = <P extends SearchResultsState<any>>(
  state: P,
  action: PayloadAction<{ error: string; timestamp: number }>,
) => {
  if (
    typeof state.searchResults.lastRequestTimestamp === 'undefined' ||
    state.searchResults.lastRequestTimestamp <= action.payload.timestamp
  ) {
    state.searchResults.isFetching = false;
  }

  console.error(action.payload.error);
};

export const getSearchResultsReducers = <P extends SearchResultsState<any>, T extends SearchResultsResponse>() => ({
  getSearchResultsAborted: onSearchResultsAbortedOrFailureAction,
  getSearchResultsFailure: onSearchResultsAbortedOrFailureAction,
  getSearchResultsRequest: (state: P, action: PayloadAction<number>) => {
    state.searchResults.isFetching = true;
    state.searchResults.lastRequestTimestamp = action.payload;
  },
  getSearchResultsSuccess: (state: P, action: PayloadAction<{ response: T; timestamp: number }>) => {
    if (state.searchResults.lastRequestTimestamp! <= action.payload.timestamp) {
      if (action.payload.response.filters) {
        state.searchResults.filters = action.payload.response.filters;
      }

      state.searchResults.isFetching = false;
      state.searchResults.totalItemCount = action.payload.response.total;

      if (instanceOfResponseType<CurvesResponse>(action.payload.response, 'curves')) {
        state.searchResults.items = action.payload.response.curves;
      } else if (instanceOfResponseType<SecuritiesResponse>(action.payload.response, 'securities')) {
        state.searchResults.items = action.payload.response.securities;
      } else if (instanceOfResponseType<SecurityInfoResponse<Dividend>>(action.payload.response, 'items')) {
        state.searchResults.items = action.payload.response.items;
      } else if (instanceOfResponseType<MarketPricesResponse>(action.payload.response, 'prices')) {
        state.searchResults.items = action.payload.response.prices;
      } else if (instanceOfResponseType<SecurityInfoResponse<PricingMeasure>>(action.payload.response, 'items')) {
        state.searchResults.items = action.payload.response.items;
      } else if (instanceOfResponseType<SecurityInfoResponse<SecurityReturn>>(action.payload.response, 'items')) {
        state.searchResults.items = action.payload.response.items;
      } else if (instanceOfResponseType<SecurityInfoResponse<SecurityHistoryItem>>(action.payload.response, 'items')) {
        state.searchResults.items = action.payload.response.items;
      }
    }
  },
  leavingSearchResultsPage: (state: P) => {
    state.searchResults.isFetching = false;
    state.searchResults.items = [];
    state.searchResults.totalItemCount = 0;
    state.searchResults.filters = [];
  },
});
