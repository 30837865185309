export enum Features {
  portfolios = 'PM-AdminTools-Portfolios',
  splitFlags = 'PM-AdminTools-SplitFlags',
  entities = 'PM-AdminTools-Entities',
  datadogRum = 'PM-AdminTools-DatadogRumSessionReplay',
}

export const MOCKED_FEATURES_MAP: SplitIO.MockedFeaturesMap = {
  [Features.portfolios]: 'off',
  [Features.splitFlags]: 'off',
  [Features.entities]: 'off',
  [Features.datadogRum]: 'off',
};
