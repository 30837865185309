import { CurvesPointsResponse } from '../types/CurvesPointsResponse';
import { createQueryString } from './utils/createQueryString';
import { makeRequest } from './utils/makeRequest';

export const getCurvesPoints = async (ids: number[], signal?: AbortSignal): Promise<CurvesPointsResponse> => {
  const url = `${window.CONFIG.API_URL}/curves-service/admin/curves/points?${createQueryString([
    { key: 'ids', value: ids.join(',') },
  ])}`;
  const response = await makeRequest(url, { signal });

  return response.json();
};
