import { combineReducers } from '@reduxjs/toolkit';
import { marketPricesReducer } from '../features/asset-master/asset-details/market-prices/marketPricesSlice';
import { curveDetailsReducer } from '../features/curves/curve-details/curveDetailsSlice';
import { curveListReducer } from '../features/curves/curve-list/curveListSlice';

export const rootReducer = combineReducers({
  curveList: curveListReducer,
  curveDetails: curveDetailsReducer,
  marketPrices: marketPricesReducer,
});
