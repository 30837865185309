import { Features, MOCKED_FEATURES_MAP } from './features';

export const splitConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: window.CONFIG.SPLIT_KEY,
    key: window.CONFIG.SPLIT_DEFAULT_KEY,
  },
  features: MOCKED_FEATURES_MAP,
  sync: { enabled: false, splitFilters: [{ type: 'byName', values: Object.values(Features) }] },
};
