import styled from '@emotion/styled';

export const ContentWrapper = styled.main`
  display: flex;
  flex-grow: 1;
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  z-index: 1;
`;

export const PageLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;
