import { User } from '@auth0/auth0-react';
import { datadogRum } from '@moodys-bss/ui-datadog';

export const setUser = (user: User | undefined) => {
  if (process.env.NODE_ENV === 'production') {
    if (user) {
      datadogRum.setUser({
        email: user.email,
        name: user.name,
        nickname: user.nickname,
      });
    } else {
      datadogRum.clearUser();
    }
  }
};
