import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCurveDefinition } from '../../../api/getCurveDefinition';
import { getCurves } from '../../../api/getCurves';
import { getCurvesPoints } from '../../../api/getCurvesPoints';
import { AppThunk } from '../../../app/AppThunk';
import { networkRequestThunk } from '../../../lib/thunk-utils/networkRequestThunk';
import { ColumnFilter } from '../../../types/ColumnFilter';
import { CurveDefinitionResponse } from '../../../types/CurveDefinitionResponse';
import { CurvesResponse } from '../../../types/CurvesResponse';
import { SortOrder } from '../../../types/SortOrder';
import { getSearchResultsReducers } from '../../getSearchResultsReducers';
import { CurvePageState } from '../CurvePageState';
import { getCurveSelectionReducers } from '../getCurveSelectionReducers';
import { CurveDetailsState } from './CurveDetailsState';

const initialState: CurveDetailsState = {
  searchResults: {
    isFetching: false,
    items: [],
    lastRequestTimestamp: 1,
  },
  selectedItems: {
    isFetching: false,
    items: [],
    points: [],
  },
  curveDefinition: {
    isFetching: false,
    details: { name: '', metadata: {} },
    lastRequestTimestamp: 1,
  },
};

const onCurveDefinitionAbortedOrFailureAction = (
  state: CurveDetailsState,
  action: PayloadAction<{ error: string; timestamp: number }>,
) => {
  if (state.curveDefinition.lastRequestTimestamp! <= action.payload.timestamp) {
    state.curveDefinition.isFetching = false;
  }
  console.error(action.payload.error);
};

const curveDetailsSlice = createSlice({
  name: 'curveDetails',
  initialState,
  reducers: {
    ...getSearchResultsReducers<CurvePageState, CurvesResponse>(),
    ...getCurveSelectionReducers(),
    getCurveDefinitionAborted: onCurveDefinitionAbortedOrFailureAction,
    getCurveDefinitionFailure: onCurveDefinitionAbortedOrFailureAction,
    getCurveDefinitionRequest: (state: CurveDetailsState, action: PayloadAction<number>) => {
      state.curveDefinition.isFetching = true;
      state.curveDefinition.lastRequestTimestamp = action.payload;
    },
    getCurveDefinitionSuccess: (
      state: CurveDetailsState,
      action: PayloadAction<{ response: CurveDefinitionResponse; timestamp: number }>,
    ) => {
      if (state.curveDefinition.lastRequestTimestamp! <= action.payload.timestamp) {
        state.curveDefinition.isFetching = false;
        state.curveDefinition.details = action.payload.response || {};
      }
    },
  },
});

export const {
  getSearchResultsFailure,
  getSearchResultsRequest,
  getSearchResultsSuccess,
  getSelectedItemsPointsAborted,
  getSelectedItemsPointsFailure,
  getSelectedItemsPointsRequest,
  getSelectedItemsPointsSuccess,
  itemDeselected,
  itemSelected,

  getCurveDefinitionFailure,
  getCurveDefinitionRequest,
  getCurveDefinitionSuccess,
  leavingSearchResultsPage,
} = curveDetailsSlice.actions;

export const sliceName = curveDetailsSlice.name;

export const { reducer: curveDetailsReducer } = curveDetailsSlice;

export const fetchCurves = (
  pageSize: number,
  searchTerms: string,
  sortOrder?: SortOrder,
  pageNumber?: number,
  returnFilters?: boolean,
  filters?: ColumnFilter[],
  abortController?: AbortController,
): AppThunk =>
  networkRequestThunk(
    getSearchResultsRequest,
    getSearchResultsSuccess,
    getSearchResultsFailure,
    () => getCurves(pageNumber, pageSize, searchTerms, sortOrder, returnFilters, filters, abortController?.signal),
    abortController,
    curveDetailsSlice.actions.getSearchResultsAborted,
  );

export const fetchCurvesPoints = (ids: number[], abortController?: AbortController): AppThunk =>
  networkRequestThunk(
    getSelectedItemsPointsRequest,
    getSelectedItemsPointsSuccess,
    getSelectedItemsPointsFailure,
    () => getCurvesPoints(ids, abortController?.signal),
    abortController,
    getSelectedItemsPointsAborted,
  );

export const fetchCurveDefinition = (curveName: string, abortController?: AbortController): AppThunk =>
  networkRequestThunk(
    getCurveDefinitionRequest,
    getCurveDefinitionSuccess,
    getCurveDefinitionFailure,
    () => getCurveDefinition(curveName, abortController?.signal),
    abortController,
    curveDetailsSlice.actions.getCurveDefinitionAborted,
  );
