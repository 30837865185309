import { osirisMoodysTheme } from '@risk-first/ui-theme';
import merge from 'lodash/merge';
import { rem } from 'polished';

const appTheme = {
  colors: {
    border: '#e6e6eb',
    chart: {
      all: [
        '#6c92cb',
        '#9c8f30',
        '#e0782e',
        '#c67aa9',
        '#bb8b07',
        '#789c0d',
        '#b21ab9',
        '#eb60b1',
        '#4186a2',
        '#158b81',
        '#c85e33',
        '#8c8a84',
        osirisMoodysTheme.colors.leafGreen,
        '#4ba097',
        osirisMoodysTheme.colors.sangriaRed,
        '#b38a1d',
        '#e43c3c',
        '#b78966',
      ],
    },
  },
  space: [0, 4, 8, 16, 32, 64],
  buttons: {
    icon: {
      primaryTransparent: {
        bg: 'transparent',
        contentColor: osirisMoodysTheme.colors.brand.primary,
        border: {
          radius: '0',
        },
      },
    },
    base: {
      linkTransparent: {
        textDecoration: 'none',
      },
    },
  },
  heading: {
    h1: {
      lineHeight: 1,
    },
    h2: {
      fontSize: rem(18),
      lineHeight: 1,
    },
  },
  picker: {
    default: {
      navigation: {
        dropdownMaxHeight: rem(300),
      },
    },
  },
};

export const theme = merge(osirisMoodysTheme, appTheme);
