import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IS_CYPRESS } from '../lib/constants/is-cypress';

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = React.useCallback(
    (appState?: { returnTo?: string }) => {
      history.push(appState?.returnTo || window.location.pathname);
    },
    [history],
  );

  const SCOPES = 'read:organization write:organization delete:organization read:user write:user delete:user';

  return (
    <Auth0Provider
      audience={window.CONFIG.AUTH_AUDIENCE}
      cacheLocation={IS_CYPRESS ? 'localstorage' : 'memory'}
      clientId={window.CONFIG.AUTH_CLIENT_ID}
      domain={window.CONFIG.AUTH_DOMAIN}
      redirectUri={window.location.origin}
      scope={SCOPES}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
