import { getErrorMessage } from './lib/convert-to-error';

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(getErrorMessage(error));
      });
  }
}
