import styled from '@emotion/styled';
import { themeGet } from '@risk-first/ui-style-utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${themeGet('space.4')}px;
  width: 100%;
`;
