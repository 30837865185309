import { createSlice } from '@reduxjs/toolkit';
import { getCurves } from '../../../api/getCurves';
import { getCurvesPoints } from '../../../api/getCurvesPoints';
import { AppThunk } from '../../../app/AppThunk';
import { networkRequestThunk } from '../../../lib/thunk-utils/networkRequestThunk';
import { ColumnFilter } from '../../../types/ColumnFilter';
import { CurvesResponse } from '../../../types/CurvesResponse';
import { SortOrder } from '../../../types/SortOrder';
import { getSearchResultsReducers } from '../../getSearchResultsReducers';
import { CurvePageState } from '../CurvePageState';
import { getCurveSelectionReducers } from '../getCurveSelectionReducers';

const initialState: CurvePageState = {
  searchResults: {
    isFetching: false,
    items: [],
    lastRequestTimestamp: 1,
  },
  selectedItems: {
    isFetching: false,
    items: [],
    points: [],
  },
};

const curveListSlice = createSlice({
  name: 'curveList',
  initialState,
  reducers: {
    ...getSearchResultsReducers<CurvePageState, CurvesResponse>(),
    ...getCurveSelectionReducers(),
  },
});

export const {
  getSearchResultsFailure,
  getSearchResultsRequest,
  getSearchResultsSuccess,
  getSelectedItemsPointsAborted,
  getSelectedItemsPointsFailure,
  getSelectedItemsPointsRequest,
  getSelectedItemsPointsSuccess,
  itemDeselected,
  itemSelected,
  leavingSearchResultsPage,
} = curveListSlice.actions;

export const sliceName = curveListSlice.name;

export const { reducer: curveListReducer } = curveListSlice;

export const fetchCurves = (
  pageSize: number,
  searchTerms: string,
  sortOrder?: SortOrder,
  pageNumber?: number,
  returnFilters?: boolean,
  filters?: ColumnFilter[],
  abortController?: AbortController,
): AppThunk =>
  networkRequestThunk(
    getSearchResultsRequest,
    getSearchResultsSuccess,
    getSearchResultsFailure,
    () => getCurves(pageNumber, pageSize, searchTerms, sortOrder, returnFilters, filters, abortController?.signal),
    abortController,
  );

export const fetchCurvesPoints = (ids: number[], abortController?: AbortController): AppThunk =>
  networkRequestThunk(
    getSelectedItemsPointsRequest,
    getSelectedItemsPointsSuccess,
    getSelectedItemsPointsFailure,
    () => getCurvesPoints(ids, abortController?.signal),
    abortController,
    getSelectedItemsPointsAborted,
  );
