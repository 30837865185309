import { useAuth0 } from '@auth0/auth0-react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import type { FC } from 'react';
import { splitConfig } from './config';

export const SplitProvider: FC = ({ children }) => {
  const { user } = useAuth0();
  const splitKey = user?.email ?? window.CONFIG.SPLIT_DEFAULT_KEY;

  return (
    <SplitFactory config={{ ...splitConfig, core: { ...splitConfig.core, key: splitKey } }}>{children}</SplitFactory>
  );
};
