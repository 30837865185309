import {
  NavigationDrawer,
  NavigationDrawerContextProvider,
  NavigationDrawerChild,
} from '@risk-first/ui-navigation-drawer';
import { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { usePortfoliosFeature, useSplitFlagsFeature } from '../split/hooks';
import { ROUTES } from './Navigation.routes';
import { StyledNavigationContent, StyledNavigationDrawerContainer } from './Navigation.style';

export const Navigation: FC = ({ children }) => {
  const match = useRouteMatch('/:page');
  const isPortfoliosFeatureOn = usePortfoliosFeature();
  const isSplitFlagsFeatureOn = useSplitFlagsFeature();

  const publicRoutes = ROUTES.reduce<typeof ROUTES>((acc, { children, ...route }) => {
    const activeChildren = children.filter(({ path }) => {
      switch (path) {
        case '/split-flags':
          return isSplitFlagsFeatureOn;
        case '/portfolios':
          return isPortfoliosFeatureOn;
        default:
          return true;
      }
    });

    return activeChildren.length ? [...acc, { ...route, children: activeChildren }] : acc;
  }, []);

  return (
    <NavigationDrawerContextProvider>
      <StyledNavigationDrawerContainer>
        <NavigationDrawer homeLink={<Link aria-label="Home" to={{ pathname: '/' }} />}>
          {publicRoutes.map(({ page, icon, children }) => (
            <NavigationDrawerChild key={page} icon={icon} text={page} uniqueId={`navigation-drawer-parent-${page}`}>
              {children.map((childRoute) => (
                <NavigationDrawerChild
                  key={childRoute.page}
                  aria-label={childRoute.page}
                  icon={childRoute.icon}
                  link={<Link to={{ pathname: childRoute.path }}>{childRoute.page}</Link>}
                  selected={match?.url === childRoute.path}
                  text={childRoute.page}
                  uniqueId={`navigation-drawer-child-${childRoute.page}`}
                />
              ))}
            </NavigationDrawerChild>
          ))}
        </NavigationDrawer>
        <StyledNavigationContent>{children}</StyledNavigationContent>
      </StyledNavigationDrawerContainer>
    </NavigationDrawerContextProvider>
  );
};
