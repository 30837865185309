import { useAuth0 } from '@auth0/auth0-react';
import React, { useLayoutEffect, useEffect } from 'react';
import getHistory from '../lib/history';

interface Props {
  children?: (token: string) => React.ReactNode;
}

const isEntityMasterRequest = (url: string) => url.startsWith(window.CONFIG.API_URL_ENTITY_SERVICES);

export const AccessToken: React.FC<Props> = ({ children }) => {
  const [token, setToken] = React.useState('');
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useLayoutEffect(() => {
    const { fetch: originalFetch } = window;
    window.fetch = async (resource, config): Promise<Response> => {
      const url = resource.toString();

      const apiUrls = [
        window.CONFIG.API_URL,
        window.CONFIG.API_URL_TEMP_SERVICES,
        window.CONFIG.API_URL_SHARED_SERVICES,
        window.CONFIG.API_URL_ATTRIBUTION_SERVICES,
        window.CONFIG.API_URL_ATLAS_SERVICES,
        window.CONFIG.API_URL_ENTITY_SERVICES,
      ];

      const isApiUrl =
        apiUrls.some((apiUrl) => typeof apiUrl !== 'undefined' && url.includes(apiUrl + '/')) ||
        url.includes('/split-api/') ||
        url.includes('/bvd-api/');

      if (isApiUrl) {
        const accessToken = await getAccessTokenSilently();

        const newConfig = {
          ...config,
          headers: {
            ...config?.headers,
            Authorization: `Bearer ${accessToken}`,
            ...(isEntityMasterRequest(url) ? { 'content-type': 'application/grpc-web-text' } : {}),
          },
        };

        if (!isAuthenticated) {
          redirectToLogout();
        }

        if (accessToken) {
          setToken(accessToken);
        }

        return originalFetch(url, newConfig);
      } else {
        throw new Error('An error occurred while fetching authentication data');
      }
    };
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        setToken(await getAccessTokenSilently());
      })();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  const redirectToLogout = () => {
    const to = `/login${getHistory().location.search}`;
    getHistory().push(to, {
      from: getHistory().location,
      message: 'Your session has expired, please login again to access your data.',
    });
  };

  return <>{children ? children(token) : null}</>;
};
