import { ColumnFilter } from '../types/ColumnFilter';
import { MarketPrice } from '../types/MarketPrice';
import { SecurityInfoRequest } from '../types/SecurityInfoRequest';
import { SecurityInfoResponse } from '../types/SecurityInfoResponse';
import { SortOrder } from '../types/SortOrder';
import { makeRequest } from './utils/makeRequest';
import { sortDirectionToApiSortDirectionMap } from './utils/sortDirectionToApiSortDirectionMap';

//@TODO: Update URL path when endpoint is available
export const getMarketPrices = async (
  rfSecurityId: string,
  pageNumber = 1,
  pageSize = 50,
  {
    filters,
    returnFilters,
    signal,
    sortOrder,
  }: {
    filters?: ColumnFilter[];
    returnFilters?: boolean;
    signal?: AbortSignal;
    sortOrder?: SortOrder;
  } = {},
): Promise<SecurityInfoResponse<MarketPrice>> => {
  const requestBody: SecurityInfoRequest = {
    rfSecurityId,
    pageNumber,
    pageSize,
  };

  if (filters?.length) {
    requestBody.filter = filters;
  }

  if (sortOrder) {
    requestBody.sort = {
      sortBy: sortOrder.field,
      sortDirection: sortDirectionToApiSortDirectionMap.get(sortOrder.direction)!,
    };
  }

  if (typeof returnFilters !== 'undefined') {
    requestBody.returnFilters = returnFilters;
  }

  const response = await makeRequest(`${window.CONFIG.API_URL}/market-prices-service/api/admin/prices/historic-data`, {
    body: JSON.stringify(requestBody),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    signal,
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error(response.statusText);
  }
};
