import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { Features } from '../features';
import { isUriToggleOn } from '../utils/is-uri-toggle-on';

export const useFeatureTreatment = (feature: Features) => {
  const queryToggle = isUriToggleOn(feature);
  const { treatments, isReady } = useSplitTreatments({ names: [feature] });
  const isEnabled = queryToggle ? queryToggle === 'true' : treatments[feature]?.treatment === 'on';

  return isEnabled && isReady;
};
