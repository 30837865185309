import { CurveDefinitionResponse } from '../types/CurveDefinitionResponse';
import { makeRequest } from './utils/makeRequest';

export const getCurveDefinition = async (curveName: string, signal?: AbortSignal): Promise<CurveDefinitionResponse> => {
    //TODO: Functions using this endpoint are currently not working and need to be fixed/updated to work with the latest version of the response. Route has also changed to 'curve-definitions/get'
    const url = `${window.CONFIG.API_URL}/curves-service/api/curveDefinitions/${encodeURIComponent(curveName)}`; 
  const response = await makeRequest(url, { signal });

  return response.json();
};
