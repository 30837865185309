import { useAuth0 } from '@auth0/auth0-react';
import { Login as LoginComponent } from '@risk-first/ui-login';
import React, { useCallback, useMemo } from 'react';
import { ReactComponent as AdminToolsLogoReactComponent } from '../../assets/logos/PFaroeAdminTools.svg';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const logo = useMemo(() => <AdminToolsLogoReactComponent width={290} />, []);

  const handleClick = useCallback(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return <LoginComponent applicationLogo={logo} applicationName="Admin Tools" onLoginClick={handleClick} />;
};

export default Login;
