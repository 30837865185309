import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/AppThunk';
import { getErrorMessage } from '../convert-to-error';

export const networkRequestThunk = <R>(
  requestAction: ActionCreatorWithPayload<number, string>,
  successAction: ActionCreatorWithPayload<{ response: R; timestamp: number }, string>,
  failureAction: ActionCreatorWithPayload<{ error: string; timestamp: number }, string>,
  getResponse: () => Promise<R>,
  abortController?: AbortController,
  abortAction?: ActionCreatorWithPayload<{ error: string; timestamp: number }, string>,
): AppThunk => async (dispatch) => {
  const timestamp = Date.now();

  dispatch(requestAction(timestamp));

  try {
    const response = await getResponse();

    dispatch(successAction({ response, timestamp }));
  } catch (err) {
    if (abortController?.signal.aborted && abortAction) {
      dispatch(abortAction({ error: getErrorMessage(err), timestamp }));
    } else {
      dispatch(failureAction({ error: getErrorMessage(err), timestamp }));
    }
  }
};
