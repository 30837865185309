import { PayloadAction } from '@reduxjs/toolkit';
import { CurvesPointsResponse } from '../../types/CurvesPointsResponse';
import { SelectedCurve } from '../../types/SelectedCurve';
import { CurvePageState } from './CurvePageState';

const selectedItemColors = ['#789BCF', '#CFC264', '#E2823C', '#C67AA9', '#F8C741'];

const onSelectedItemsPointsAbortedOrFailureAction = (
  state: CurvePageState,
  action: PayloadAction<{ error: string; timestamp: number }>,
) => {
  if (state.selectedItems.lastRequestTimestamp! <= action.payload.timestamp) {
    state.selectedItems.isFetching = false;
  }

  console.error(action.payload.error);
};

export const getCurveSelectionReducers = () => ({
  getSelectedItemsPointsAborted: onSelectedItemsPointsAbortedOrFailureAction,
  getSelectedItemsPointsFailure: onSelectedItemsPointsAbortedOrFailureAction,
  getSelectedItemsPointsRequest: (state: CurvePageState, action: PayloadAction<number>) => {
    state.selectedItems.isFetching = true;
    state.selectedItems.lastRequestTimestamp = action.payload;
  },
  getSelectedItemsPointsSuccess: (
    state: CurvePageState,
    action: PayloadAction<{ response: CurvesPointsResponse; timestamp: number }>,
  ) => {
    if (state.selectedItems.lastRequestTimestamp! <= action.payload.timestamp) {
      state.selectedItems.isFetching = false;
      state.selectedItems.points = action.payload.response.curves || [];
    }
  },
  itemDeselected: (state: CurvePageState, action: PayloadAction<number>) => {
    state.selectedItems.items = state.selectedItems.items.filter(({ id }) => id !== action.payload);
    state.selectedItems.points = state.selectedItems.points.filter(({ id }) => id !== action.payload);
  },
  itemSelected: (state: CurvePageState, action: PayloadAction<number>) => {
    if (state.selectedItems.items.every(({ id }) => id !== action.payload)) {
      if (state.searchResults.items.some(({ id }) => id === action.payload)) {
        const selectedCurve: SelectedCurve = {
          ...state.searchResults.items.find(({ id }) => id === action.payload)!,
          color: selectedItemColors.find(
            (color) => !state.selectedItems.items.map((selectedItem) => selectedItem.color).includes(color),
          )!,
        };

        state.selectedItems.items.push(selectedCurve);
      }
    }
  },
});
