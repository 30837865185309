export const convertToError = (err: unknown): Error => {
  switch (typeof err) {
    case 'object':
      if (err instanceof Error) return err;
      if (err instanceof String) return new Error(err.toString());
      break;
    case 'string':
      return new Error(err);
  }

  return new Error('unknown error');
};

export const getErrorMessage = (err: unknown): string => {
  switch (typeof err) {
    case 'object':
      if (err instanceof Error) return (err as Error).message;
      if (err instanceof String) return err.toString();
      break;
    case 'string':
      return err;
  }

  return 'unknown error';
};
