import { ColumnFilter } from '../types/ColumnFilter';
import { CurvesRequest } from '../types/CurvesRequest';
import { CurvesResponse } from '../types/CurvesResponse';
import { SortOrder } from '../types/SortOrder';
import { makeRequest } from './utils/makeRequest';
import { sortDirectionToApiSortDirectionMap } from './utils/sortDirectionToApiSortDirectionMap';

export const getCurves = async (
  pageNumber = 1,
  pageSize = 50,
  searchTerms = '',
  sortOrder?: SortOrder,
  returnFilters = false,
  filters?: ColumnFilter[],
  signal?: AbortSignal,
): Promise<CurvesResponse> => {
  const requestBody: CurvesRequest = {
    pageNumber,
    pageSize,
    returnFilters,
  };

  if (searchTerms) {
    requestBody.search = {
      columnNames: ['name'],
      term: searchTerms,
    };
  }

  if (sortOrder) {
    requestBody.sort = {
      sortBy: sortOrder.field,
      sortDirection: sortDirectionToApiSortDirectionMap.get(sortOrder.direction)!,
    };
  }

  if (filters) {
    requestBody.filter = filters;
  }

  const response = await makeRequest(`${window.CONFIG.API_URL}/curves-service/admin/curves/search`, {
    body: JSON.stringify(requestBody),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    signal,
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error(response.statusText);
  }
};
