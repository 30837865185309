type HistoryMethodArguments =
  | Parameters<typeof window.history['pushState']>
  | Parameters<typeof window.history['replaceState']>;

export const patchHistoryMethod = (method: 'pushState' | 'replaceState') => {
  const history = window.history;
  const original = history[method];

  history[method] = function (state: HistoryMethodArguments[0]) {
    const result = original.apply(this, arguments as unknown as HistoryMethodArguments);
    const event = new Event(method.toLowerCase());

    (event as any).state = state;
    window.dispatchEvent(event);

    return result;
  };
};
