import { initRum } from '@moodys-bss/ui-datadog';
import { useEffect } from 'react';
import { useDatadogRumSessionReplayFeature } from '../../split/hooks';
import { isTrackingEnabled } from './is-tracking-enabled';

export const useInitRum = () => {
  const isSessionReplayEnabled = useDatadogRumSessionReplayFeature();

  useEffect(() => {
    if (isTrackingEnabled()) {
      initRum({
        allowedTracingUrls: [
          window.CONFIG.API_URL,
          window.CONFIG.API_URL_TEMP_SERVICES,
          window.CONFIG.API_URL_SHARED_SERVICES,
        ].filter(Boolean),
        applicationId: '4e0e3e4a-fea7-46a0-a078-1b59572525d4',
        clientToken: 'pubab8d1743ce571025fae1548154dc3416',
        env: 'prod',
        sessionSampleRate: 100,
        service: 'am-admin-tools-ui',
        site: 'datadoghq.com',
        trackUserInteractions: true,
        version: window.CONFIG.VERSION,
        isSessionReplayEnabled: isSessionReplayEnabled,
        defaultPrivacyLevel: 'mask',
      });
    }
  }, [isSessionReplayEnabled]);
};
