import { createSlice } from '@reduxjs/toolkit';
import { getMarketPrices } from '../../../../api/getMarketPrices';
import { AppThunk } from '../../../../app/AppThunk';
import { networkRequestThunk } from '../../../../lib/thunk-utils/networkRequestThunk';
import { ColumnFilter } from '../../../../types/ColumnFilter';
import { MarketPrice } from '../../../../types/MarketPrice';
import { SecurityInfoResponse } from '../../../../types/SecurityInfoResponse';
import { SortOrder } from '../../../../types/SortOrder';
import { getSearchResultsReducers } from '../../../getSearchResultsReducers';
import { MarketPricesState } from './MarketPricesState';

const initialState: MarketPricesState = {
  searchResults: {
    isFetching: false,
    items: [],
  },
};

const marketPricesSlice = createSlice({
  name: 'marketPrices',
  initialState,
  reducers: {
    ...getSearchResultsReducers<MarketPricesState, SecurityInfoResponse<MarketPrice>>(),
  },
});

export const { getSearchResultsFailure, getSearchResultsRequest, getSearchResultsSuccess } = marketPricesSlice.actions;

export const { reducer: marketPricesReducer } = marketPricesSlice;

export const fetchMarketPrices = (
  rfSecurityId: string,
  pageSize: number,
  sortOrder?: SortOrder,
  pageNumber?: number,
  abortController?: AbortController,
  returnFilters?: boolean,
  filters?: ColumnFilter[],
): AppThunk =>
  networkRequestThunk(
    getSearchResultsRequest,
    getSearchResultsSuccess,
    getSearchResultsFailure,
    () =>
      getMarketPrices(rfSecurityId, pageNumber, pageSize, {
        filters,
        returnFilters,
        signal: abortController?.signal,
        sortOrder,
      }),
    abortController,
  );
