import { Global, css, useTheme } from '@emotion/react';
import { themeGetString } from '@risk-first/ui-style-utils';
import robotoRegular from '../assets/fonts/roboto-400.woff2';
import robotoMedium from '../assets/fonts/roboto-500.woff2';
import robotoBold from '../assets/fonts/roboto-700.woff2';

export const GlobalFonts = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        /* Latin - Regular */
        @font-face {
          font-family: Roboto;
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: local(Roboto), local('Roboto-Regular'), url(${robotoRegular}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        /* Latin - Medium */
        @font-face {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: local('Roboto Medium'), local('Roboto-Medium'), url(${robotoMedium}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        /* Latin - Bold */
        @font-face {
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: local('Roboto Bold'), local('Roboto-Bold'), url(${robotoBold}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        body {
          font-family: ${themeGetString('fontFamily')({ theme })};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
        }
      `}
    />
  );
};
