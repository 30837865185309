import styled from '@emotion/styled';
import { NavigationDrawerContainer } from '@risk-first/ui-navigation-drawer';
import { themeGet } from '@risk-first/ui-style-utils';

export const StyledNavigationDrawerContainer = styled(NavigationDrawerContainer)`
  top: 50px;
  height: initial;

  & > :first-of-type {
    z-index: 2;
    border-top: solid 1px ${themeGet('colors.grayishBlue')};
    ${themeGet('mediaQueries.desktop')} {
      z-index: 0;
    }
  }

  & > :last-child {
    z-index: 1;
  }
`;

export const StyledNavigationContent = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: auto;
`;
